import locale from "dayjs/locale";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

dayjs.extend(utc);
dayjs.extend(timezone);

export function getCompleteDateTimeLocale(date) {
  return dayjs(date).format("L HH:mm:ss");
}

export function getTimeLocaleFormatTimezone(date, timezone, second = null) {
  const tz = timezone ? timezone : "Europe/Paris";
  return second ? dayjs(date).tz(tz).format("HH:mm:ss") : dayjs(date).tz(tz).format("HH:mm");
}

export function getDateLocaleFormatTimezone(date, timezone) {
  const tz = timezone ? timezone : "Europe/Paris";
  return dayjs(date).tz(tz).format("L");
}

export function getTimeLocaleFormat(date) {
  return dayjs(date).format("HH:mm");
}

export function getDateLocaleFormat(date) {
  return dayjs(date).format("L");
}

export function getDayJSAdapterLocale(lang) {
  if (typeof lang !== "string") {
    return "en";
  }

  const findByLongLang = locale.find((l) => l.key === lang);
  if (!findByLongLang) {
    const langShort = lang.slice(0, 2);
    const findByShortLang = locale.find((l) => l.key === langShort);
    if (!findByShortLang) {
      return "en";
    } else {
      return findByShortLang.key;
    }
  } else {
    return findByLongLang.key;
  }
}

export async function loadLocale(locale) {
  try {
    await import(`dayjs/locale/${locale}.js`);
  } catch (error) {
    console.error(`Error loading locale ${locale}.js:`, error);
  }
}

export function isValidDate(d) {
  return dayjs(d).isValid();
}

export function isDateHigher(date1, date2) {
  return dayjs(date1).isAfter(dayjs(date2));
}

export function isDateLower(date1, date2) {
  return dayjs(date1).isBefore(dayjs(date2));
}

export function constructOneMonth(changeMonth = 0) {
  const searchDate = dayjs().add(changeMonth, "month");
  const startMonth = searchDate.startOf("month").toDate();
  const endMonth = searchDate.endOf("month").toDate();
  return [startMonth, endMonth];
}

export function constructOneYear(changeYear = 0) {
  const searchYear = dayjs().add(changeYear, "year");
  const startYear = searchYear.startOf("year").toDate();
  const endYear = searchYear.endOf("year").toDate();
  return [startYear, endYear];
}

export function isDateAndMinuteLower(date1, date2) {
  const normalizedDate1 = dayjs(date1).startOf("minute");
  const normalizedDate2 = dayjs(date2).startOf("minute");

  return normalizedDate1.isBefore(normalizedDate2);
}
